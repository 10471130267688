import {AnonymousFunction} from 'types';

export const commonLocalization = {


    //region choose
    chooseTheme: 'Vali kujundus',
    chooseReviews: 'Valin 3 hinnangut',
    choosePictureTitle: 'Vali seadmest pilt...',
    choosePdfTitle: 'Vali seadmest PDF',
    choosePdfTitleButton: 'Vali PDF',
    chooseVideoTitle: 'Vali seadmest video(.mp4,.ogg), FullHD(1920x1080), max(8MB) ',
    chooseButtonTitle: 'Vali pilt',
    chooseButtonVideoTitle: 'Vali video',
    choosePicture: 'Vali pilt',
    chooseTextFromBank: 'Vali sobiv tekst tekstipangast',
    chooseHomepageColors: 'Vali kodulehe värvid',
    chooseYear: 'Vali aasta',
    chooseArea: 'Vali piirkond',
    //endregion

    linkExpires: 'Link aegub',
    switchActive: 'Teenus aktiivne',
    switchInactive: 'Teenus mitteaktiivne',
    closeModule: 'Sulgen mooduli',
    cancel: 'Katkesta',
    lookExample: 'Vaata näidist',
    speedTitle: 'Töökiirus:',
    qualityTitle: 'Kvaliteet:',
    priceTitle: 'Hind:',
    serverError: 'Server Error 500',
    unknownError: 'Tundmatu viga',
    errorTitle: 'Viga!',
    loadingOverlayTitle: 'Palun oota, laadin...',
    addNewRow: 'Lisa uus rida',
    missingOptions: 'Valikud puudu',
    businessClientSelfService: 'Ärikliendi iseteenindus',

    region: 'Piirkond',


    savedOnly: 'Salvestatud',
    saveAndPublish: 'Salvesta & Avalda',
    save: 'Salvesta',
    saveChange: 'Salvesta muutus',
    formResetButton: 'Tühista',
    formAddNewButton: 'Lisa uus',
    countPictures: 'fotot',
    viewProfile: 'Vaata avalikku profiili',
    viewOwnHomepage: 'Vaata oma kodulehte',

    addEditCategories: 'Lisa või muuda kategooriat',
    categories: 'Kategooriad',

    addEditUnits: 'Lisa või muuda ühikuid',
    units: 'Ühikud',

    workDescription: 'Töö kirjeldus',

    //region statistics
    homepageVisitStatistics: 'Kodulehe külastatavuse statistika',
    hangeProfileCompanydatabaseStatistics: 'PROFIIL HANGE.EE FIRMADE ANDMEBAASIS',
    //endregion

    //region login
    loginTitle: 'Logi sisse',
    createNewUserForCompany: 'Loo kasutaja ettevõttele',
    forgotTitle: 'Parooli taastamine',
    generateTitle: 'Loo kasutaja',
    emailPlaceholder: 'Sisesta e-maili aadress',
    passwordPlaceholder: 'Sisesta parool',
    confirmPasswordPlaceholder: 'Parool uuesti',
    rememberMe: 'Jäta mind meelde',
    forgotPassword: 'Unustasid parooli?',
    loginButton: 'Logi sisse',
    forgotButton: 'Saada uus parool',
    generateNewAccount: 'Loo uus kasutaja',
    restorePassword: 'Taasta parool',
    required: 'field is required',
    emailError: 'emailError',
    passwordError: 'passwordError',
    confirmPasswordError: 'confirmPasswordError',
    //endregion

    //region CRM
    seo: 'SEO',
    ratings: 'Hinnangud',
    videos: 'Videod',
    pictures: 'Pildid',
    fields: 'Valdkonnad',
    keywords: 'Märksõnad',
    contacts: 'Kontaktid',
    texts: 'Tekstid',
    enterText: 'Sisesta text',
    bigNumbersTitle: 'Suured numbrid',
    enterNewText: 'Kirjuta uus text',
    generalData: 'Üldandmed',
    designSelection: 'Kujunduse valimine',
    chooseColors: 'Värvid',
    mtr: 'MTR',
    services: 'Lisateenused',
    //region general info
    choosePicturesFromGallery: 'Vali galeriist pilte',
    uploadLogo: 'Laadi üles logo',
    uploadPhoto: 'Laadi üles pilt',
    meisterExpertSign: 'Ekspert/meister märk',
    //endregion

    //region contacts
    companyName: 'Ettevõtte nimi',
    //endregion

    //endregion

    confirmDelete: (confirm: AnonymousFunction) => {
        if (window.confirm('Oled kindel, et soovid kustutadad')) {
            confirm();
        }
    }
};
